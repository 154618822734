<template>
<div>
    <b-row>
        <b-col cols="4" sm="3" v-for="(s,index) in sticker" :key="index" class="mt-2 mb-2">
            <img :src="'https://api2.novelrealm.com/resources/sticker/'+s.url" class="stk" :alt="s.title" @click="selectedStk(s.slug)" width="90">
        </b-col>
    </b-row>
</div>
</template>

<script>
export default {
    props: ['sticker'],
    data() {
        return {

        }
    },
    async mounted() {},
    methods: {
        selectedStk(slug) {
            this.$emit('stickerSelected',slug)
        }
    }
}
</script>

<style scoped>
.stk:hover {
    cursor: pointer;
    background-color: rgba(212, 230, 237, 0.4);
    border-radius: 10px;
}
</style>
