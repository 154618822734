<template>
<div>
    <b-modal v-model="showStickerModal" :hide-header="false" title="เลือกสติกเกอร์" :hide-footer="true">
        <Sticker :sticker="sticker" @stickerSelected="stickerSelected($event)" />
    </b-modal>
    <div class="comment mb-2" v-if="type == 'novel'">
        <span>แสดงคอมเม็นท์ในตอน: </span>
        <a-switch default-un-checked @change="comment_view_mode">
            <a-icon class="vertical-align-middle" slot="checkedChildren" type="check" />
            <a-icon class="vertical-align-middle" slot="unCheckedChildren" type="close" />
        </a-switch>
    </div>
    <div class="comment mb-5" v-if="isLogin">
        <b-row>
            <b-col>
                <b-form-textarea id="textarea" class="newComment" v-model="newComment" placeholder="แสดงความคิดเห็น..." rows="3" max-rows="6"></b-form-textarea>
                <div class="tools">
                    <span class="tool" @click="addTool('bold','new')">
                        <b-icon-type-bold font-scale="1.2"></b-icon-type-bold>
                    </span>
                    <span class="tool" @click="addTool('img','new')">
                        <b-icon-image font-scale="1.2"></b-icon-image>
                    </span>
                    <span class="tool" @click="addTool('url','new')">
                        <b-icon-link45deg font-scale="1.2"></b-icon-link45deg>
                    </span>
                    <span class="tool" @click="addTool('stk','new')">
                        <b-icon-egg-fried font-scale="1.2"></b-icon-egg-fried>
                    </span>
                    <span class="commentBtn" @click="sendComment('new')" v-if="newComment!=''">
                        ส่งความคิดเห็น
                    </span>
                </div>
            </b-col>
        </b-row>
    </div>
    <div class="text-center" v-if="isLoadData">
        <b-row>
            <b-col>
                <b-spinner variant="primary" style="color:#042446 !important" label="Text Centered"></b-spinner>
            </b-col>
        </b-row>
    </div>
    <div v-else class="comment mb-3" v-for="(comment,index) in allComment.data" :key="index">
        <b-row :id="'comment'+index">
            <b-col cols="2" sm="1" class="profileImg">
                <b-avatar v-if="comment.member_profile_url!=''" variant="primary" :src="comment.member_profile_url"></b-avatar>
                <b-avatar v-else variant="primary" :src="require('@/assets/no-avartar.jpg')" ></b-avatar>
            </b-col>
            <b-col>
                <div>
                    <a-icon class="mr-2 verticle-middle" type="pushpin" theme="twoTone" two-tone-color="#f50" v-if="comment.comment_pin == 1"/>
                    <span class="name mr-2">{{comment.member_displayname}}</span>
                    <span class="time">{{comment.comment_moment}}</span>
                    <a-badge class="ml-2" v-if="comment.chapter_ep != false" :count="comment.chapter_ep" :overflow-count="99999" />
                </div>
                <div v-if="clickEdit == false">
                    <div class="commentDetail" v-html="comment.comment_detail"></div>
                    <div class="mt-3" v-if="isLogin">
                        <a-icon v-if="comment.is_like" class="pink" type="like" theme="filled" @click="likeOrDislike(comment.comment_id,1,index,comment.is_like)" />
                        <a-icon v-else class="pink" type="like" theme="outlined" @click="likeOrDislike(comment.comment_id,1,index,comment.is_like)" />
                        <span class="pink ml-1 mr-4">{{ comment.like }}</span>
                        <a-icon v-if="comment.is_dislike" class="pink" type="dislike" theme="filled" @click="likeOrDislike(comment.comment_id,2,index,comment.is_dislike)" />
                        <a-icon v-else class="pink" type="dislike" theme="outlined" @click="likeOrDislike(comment.comment_id,2,index,comment.is_dislike)" />
                        <span class="pink ml-1 mr-4">{{ comment.dislike }}</span>
                        <span class="comment_txt_btn mr-4" @click="getReplyComment(comment.comment_id)">ตอบกลับ</span>
                        <div class="display-inline" v-if="userInfo.member_is_admin == 1">
                            <span v-if="comment.comment_pin == 1" class="comment_txt_btn pink mr-4" @click="pinUnpinComment(comment.comment_id)">ถอนหมุด</span>
                            <span v-else class="comment_txt_btn mr-4" @click="pinUnpinComment(comment.comment_id)">ปักหมุด</span>
                        </div>
                        <b-icon-pen class="pen mr-4" v-if="comment.self" @click="clickEditComment(comment)"></b-icon-pen>
                        <b-icon-trash class="trash" v-if="comment.self" @click="delComment(comment.comment_id)"></b-icon-trash>
                    </div>
                </div>
                <div class="editComment" v-else-if="clickEdit && edit_commentid == comment.comment_id">
                    <b-row>
                        <b-col style="padding:0px">
                            <b-form-textarea :autofocus="true" id="textarea" class="newComment" v-model="editComment" placeholder="แสดงความคิดเห็น..." rows="3" max-rows="6"></b-form-textarea>
                            <div class="tools">
                                <span class="tool" @click="addTool('bold','edit')">
                                    <b-icon-type-bold font-scale="1.2"></b-icon-type-bold>
                                </span>
                                <span class="tool" @click="addTool('img','edit')">
                                    <b-icon-image font-scale="1.2"></b-icon-image>
                                </span>
                                <span class="tool" @click="addTool('url','edit')">
                                    <b-icon-link45deg font-scale="1.2"></b-icon-link45deg>
                                </span>
                                <span class="tool" @click="addTool('stk','edit')">
                                    <b-icon-egg-fried font-scale="1.2"></b-icon-egg-fried>
                                </span>
                                <div v-if="$vssWidth<=540" class="mt-2"></div>
                                <span class="commentBtn" @click="sendEditComment()" v-if="editComment!=''">
                                    บันทึก
                                </span>
                                <span class="commentBtn ml-2" @click="clickEdit = false">
                                    ยกเลิก
                                </span>

                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
        <ReplyComment :replyComment="replyComment" v-if="comment.comment_id == replyComment.comment_id" @getReplyComment="getReplyComment(replyComment.comment_id)" />
    </div>
    <div v-if="commentLastIndex < commentCount" class="comment mb-3 text-center">
        <span class="pink comment_txt_btn" @click="getComment()">แสดงคอมเม้นเพิ่มเติม</span>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import ReplyComment from './ReplyComment'
import Sticker from './Sticker'
import bbCodeParser from 'js-bbcode-parser';
import {
    mapGetters
} from 'vuex'

export default {
    props: ['type', 'id'],
    components: {
        ReplyComment,
        Sticker
    },
    data() {
        return {
            isLoadData: false,
            userInfo: JSON.parse(localStorage.getItem('UserInfo')),
            isLogin: localStorage.getItem('UserInfo') != null,
            newComment: "",
            allComment: {
                data: []
            },
            replyComment: {
                comment_id: null,
                detail: null
            },
            clickEdit: false,
            edit_commentid: 0,
            editComment: "",
            sticker: [],
            stickerForNewComment: true,
            showStickerModal: false,
            commentLastIndex: 0,
            commentCount: 0,
            commentMode: false
        };
    },
    computed: {
        ...mapGetters(['GET_MODE'])
    },
    watch: {
        GET_MODE() {
            this.checkMode()
        }
    },
    mounted() {
        this.getComment()
        this.getSticker()
    },
    methods: {
        async addTool(tool, type) {
            if (type == 'new') {
                if (tool == 'bold') {
                    this.newComment += '[b]กรอกข้อความ[/b]\r\n'
                } else if (tool == 'img') {
                    this.newComment += '[img]urlของรูป[/img]\r\n'
                } else if (tool == 'url') {
                    this.newComment += '[url=linkที่ต้องการ]ข้อความที่ต้องการแสดง[/url]\r\n'
                } else if (tool == 'stk') {
                    this.stickerForNewComment = true
                    this.showStickerModal = true
                }
            } else if (type == 'edit') {
                if (tool == 'bold') {
                    this.editComment += '[b]กรอกข้อความ[/b]\r\n'
                } else if (tool == 'img') {
                    this.editComment += '[img]urlของรูป[/img]\r\n'
                } else if (tool == 'url') {
                    this.editComment += '[url=linkที่ต้องการ]ข้อความที่ต้องการแสดง[/url]\r\n'
                } else if (tool == 'stk') {
                    this.stickerForNewComment = false
                    this.showStickerModal = true
                }
            }
        },
        stickerSelected(slug) {
            if (this.stickerForNewComment) {
                this.newComment += slug + '\r\n'
            } else {
                this.editComment += slug + '\r\n'
            }
            this.showStickerModal = false
        },
        async getSticker() {
            const {
                data
            } = await axios.get('https://api2.novelrealm.com/json/sticker/sticker.json')
            this.sticker = data
        },
        async getComment(reset = false) {
            this.isLoadData = true
            const formData = new FormData();
            formData.append('start', this.commentLastIndex);
            formData.append('limit', 10);
            formData.append('mode', this.commentMode);
            if (this.isLogin) {
                formData.append('auth_memberid', this.userInfo.auth_memberid);
                formData.append('auth_reference', this.userInfo.auth_token);
            }
            if (this.type == 'chapter') {
                formData.append('chapter_id', this.$route.params.chapter_id);
            } else if (this.type == 'novel') {
                formData.append('novel_id', this.$route.params.novel_id);
            }
            try {
                await axios.post('https://api2.novelrealm.com/?module=comment&submodule=view', formData)
                    .then((res) => {
                        //console.log(res.data);
                        this.commentCount = parseInt(res.data.count)
                        if(this.commentCount === 0){
                            if(this.commentLastIndex === 0){
                                this.allComment.data = []
                            }
                            this.isLoadData = false
                            setTimeout(() => {
                                this.checkMode()
                            }, 100);
                            return
                        }
                        if(reset)
                            this.allComment.data = res.data.data
                        else
                            this.allComment.data = [ ...this.allComment.data, ...res.data.data]
                        if (this.allComment.data != null) {
                            var moment = require('moment'); // require
                            moment().format();
                            this.allComment.data.forEach(element => {
                                element["comment_moment"] = moment(element.comment_date).fromNow();
                            });
                        }
                        this.isLoadData = false
                        this.commentLastIndex = this.commentLastIndex+10
                        setTimeout(() => {
                            this.checkMode()
                        }, 100);
                    })
            } catch (err) {
                console.log('FAILURE!!' + err)
            }
        },
        async getReplyComment(comment_id) {
            //console.log(comment_id)
            const formData = new FormData();
            formData.append('start', 0);
            formData.append('limit', 10);
            if (this.isLogin) {
                formData.append('auth_memberid', this.userInfo.auth_memberid);
                formData.append('auth_reference', this.userInfo.auth_token);
            }
            formData.append('comment_id', comment_id);
            try {
                await axios.post('https://api2.novelrealm.com/module/comment&submodule=view', formData)
                    .then((res) => {
                        if (res.data.count > 0) {
                            this.replyComment = {
                                comment_id: comment_id,
                                detail: res.data.data
                            }
                            var moment = require('moment'); // require
                            moment().format();
                            this.replyComment.detail.forEach(element => {
                                element["comment_moment"] = moment(element.comment_date).fromNow();
                            });
                        } else {
                            this.replyComment = {
                                comment_id: comment_id,
                                detail: null
                            }
                        }
                        setTimeout(() => {
                            this.checkMode()
                        }, 10);
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        async likeOrDislike(comment_id, type, index, statusBeforeClick) {
            //console.log('statusBeforeClick : ', statusBeforeClick)
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            formData.append('comment_id', comment_id);
            formData.append('type', type); // type 1/2 (1=like, 2=dislike)
            try {
                await axios.post('https://api2.novelrealm.com/module/comment&submodule=like', formData)
                    .then((res) => {
                        //console.log("likeOrDislike : ", res);
                        //console.log("this.allComment : ", this.allComment);
                        if (type == 1) {
                            this.allComment.data[index].is_like = !statusBeforeClick
                            //console.log("1 : ", this.allComment.data[index].is_like)
                        } else if (type == 2) {
                            this.allComment.data[index].is_dislike = !statusBeforeClick
                            //console.log("2 : ", this.allComment.data[index].is_dislike)
                        }
                        this.allComment.data[index].like = res.data.like
                        this.allComment.data[index].dislike = res.data.dislike
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        async sendComment(newOrReply) {
            this.isLoadData = true
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            if (newOrReply == 'new' && this.type == 'chapter') {
                formData.append("chapter_id", this.id);
            } else if (newOrReply == 'new' && this.type == 'novel') {
                formData.append("novel_id", this.id);
            } else {
                formData.append("comment_id", newOrReply);
            }
            formData.append("msg", bbCodeParser.parse(this.newComment));
            try {
                await axios.post('https://api2.novelrealm.com/module/comment&submodule=add', formData)
                    .then((res) => {
                        //console.log('add comment : ', res)
                        if (res.data.status == 200) {
                            this.getComment()
                            this.newComment = ""
                            this.isLoadData = false
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        clickEditComment(comment) {
            setTimeout(() => {
                this.checkMode()
            }, 5);
            this.clickEdit = true
            this.edit_commentid = comment.comment_id
            this.editComment = comment.comment_detail

            this.editComment = this.editComment.replace(/<strong>/g, "[b]")
            this.editComment = this.editComment.replace(/<\/strong>/g, "[/b]")
            this.editComment = this.editComment.replace(/<br \/>/g, "")

            var comment_sentence = this.editComment

            var src = 'src="'
            var count_src_all = comment_sentence.match(/src="/g) == null ? 0 : comment_sentence.match(/src="/g).length
            var index_src = []
            var count_src_found = 0
            var srcOfImg = []

            for (var index1 = 0; index1 < comment_sentence.length; index1++) {
                if (comment_sentence.indexOf(src, index1) != -1) {
                    index_src.push(comment_sentence.indexOf(src, index1))
                    count_src_found += 1
                    index1 = comment_sentence.indexOf(src, index1)

                    srcOfImg.push(comment_sentence.substring(index1 + 5, comment_sentence.indexOf('"', index1 + 5)))
                    if (count_src_found == count_src_all) {
                        break;
                    }
                }
            }

            for (var n = 0; n < srcOfImg.length; n++) {
                if (srcOfImg[n].match('sticker/pack/')) {
                    var url_sticker = srcOfImg[n].substring(srcOfImg[n].indexOf('pack/'))
                    var alt = this.sticker[this.sticker.findIndex(x => x.url === url_sticker)].title
                    var numberFront = srcOfImg[n].substring(srcOfImg[n].indexOf('pack/') + 5, srcOfImg[n].indexOf('pack/') + 7)
                    var numberBack = srcOfImg[n].substring(srcOfImg[n].indexOf('pack/') + 8, srcOfImg[n].indexOf('pack/') + 10)
                    this.editComment = this.editComment.replace('<img src="' + srcOfImg[n] + '" alt="' + alt + '" class="img-fluid comment-sticker">', "[sticker-" + numberFront + "-" + numberBack + "]")
                } else {
                    this.editComment = this.editComment.replace('<img src="' + srcOfImg[n] + '">', "[img]" + srcOfImg[n] + "[/img]")
                }
            }

            var href = 'href="'
            var count_href_all = comment_sentence.match(/href="/g) == null ? 0 : comment_sentence.match(/href="/g).length
            var index_href = []
            var count_href_found = 0
            var hrefOfLink = []
            for (var index = 0; index < comment_sentence.length; index++) {
                if (comment_sentence.indexOf(href, index) != -1) {
                    index_href.push(comment_sentence.indexOf(href, index))
                    index = comment_sentence.indexOf(href, index)

                    hrefOfLink.push({
                        href: comment_sentence.substring(index + 6, comment_sentence.indexOf('"', index + 6)),
                        text: comment_sentence.substring(comment_sentence.indexOf('>') + 1, comment_sentence.indexOf('<', index + 6))
                    })
                    //console.log("hrefOfLink : ", hrefOfLink)
                    this.editComment = this.editComment.replace(/<a href/g, "[url")
                    this.editComment = this.editComment.replace(/<\/a>/g, "[/url]")
                    this.editComment = this.editComment.replace('"' + hrefOfLink[count_href_found].href + '">', hrefOfLink[count_href_found].href + ']')
                    count_href_found += 1
                    if (count_href_found == count_href_all) {
                        break;
                    }
                }
            }
        },
        async sendEditComment() {
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            formData.append("comment_id", this.edit_commentid);
            formData.append("msg", bbCodeParser.parse(this.editComment));
            try {
                await axios.post('https://api2.novelrealm.com/module/comment&submodule=edit', formData)
                    .then((res) => {
                        //console.log('edit comment : ', res)
                        if (res.data.status == 200) {
                            // this.allComment.data[index].comment_detail = bbCodeParser.parse(this.editComment)
                            //console.log(this.allComment.data[index].comment_detail)
                            this.clickEdit = false
                            this.edit_commentid = 0
                            this.editComment = ""
                            this.getComment()
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        async delComment(comment_id) {
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            formData.append("comment_id", comment_id);
            try {
                await axios.post('https://api2.novelrealm.com/module/comment&submodule=delete', formData)
                    .then((res) => {
                        //console.log('del comment : ', res)
                        if (res.data.status == 200) {
                            this.getComment()
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        async pinUnpinComment(comment_id) {
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            formData.append("comment_id", comment_id);
            try {
                await axios.post('https://api2.novelrealm.com/module/comment&submodule=pin', formData)
                    .then((res) => {
                        //console.log('del comment : ', res)
                        if (res.data.status == 200) {
                            this.getComment()
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        comment_view_mode(checked){
            this.commentLastIndex = 0
            this.commentMode = checked
            this.getComment(true)
        },
        checkMode() {
            if (this.GET_MODE == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementsByClassName('comment').forEach(element => {
                element.classList.remove('darkmode')
            });
            document.getElementsByClassName('newComment').forEach(element => {
                element.classList.remove('darkmode')
            });
        },
        darkmode() {
            document.getElementsByClassName('comment').forEach(element => {
                element.classList.add('darkmode')
            });
            document.getElementsByClassName('newComment').forEach(element => {
                element.classList.add('darkmode')
            });
        }
    }
};
</script>

<style scoped>
.vertical-align-middle{
    vertical-align: middle;
}

.comment {
    padding: 2% 2% 2% 2%;
    border: 2px solid #ffffff;
    border-radius: 7px;
    background-color: #ffffff;
}

.darkmode {
    background-color: #35363A;
    border: 2px solid #35363A;
    color: #ffffff;
}

.darkmode:focus {
    background-color: #35363A;
    color: #ffffff;
}

.newComment {
    border: none;
    padding: 0px;
}

.form-control:focus {
    box-shadow: none;
}

.editComment {
    padding: 0;
}

.tools {
    padding: 0.3rem 0rem 0rem 0rem;
}

.tool {
    margin-right: 1%;
    padding: 7px
}

.tool:hover {
    color: #040246;
    cursor: pointer;
    background-color: rgba(212, 230, 237, 0.4);
    border-radius: 6px;
}

.pink {
    color: #EE806B;
    font-size: 14px;
}

.badge-primary {
    color: #fff;
    border: 1px solid #040246;
}

.name {
    font-size: 14px;
}

.time {
    font-size: 12px;
    color: #ADADAD;
}

.comment_txt_btn {
    font-size: 13px;
}

.comment_txt_btn:hover {
    cursor: pointer;
}

.commentBtn {
    background-color: #F5D087;
    border-radius: 6px;
    padding: 6px 10px;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
}

.commentBtn:hover {
    background-color: #FAC762;
    cursor: pointer;
}

.pen,
.trash {
    font-size: 17px;
}

.pen:hover,
.trash:hover {
    cursor: pointer;
}
.commentDetail{
    word-break: break-word;
}

.commentDetail:deep(img) {
    max-width: 150px !important;
}

.verticle-middle{
    vertical-align: middle;
}

.display-inline{
    display: inline;
}

@media screen and (max-width: 500px) {
    .profileImg {
        padding: 7px;

    }
}
</style>
