<template>
<div class="mt-4">
    <b-modal v-model="showStickerModal" :hide-header="false" title="เลือกสติกเกอร์" :hide-footer="true">
        <Sticker :sticker="sticker" @stickerSelected="stickerSelected($event)" />
    </b-modal>
    <div class="comment ml-4" v-for="(comment,index) in replyComment.detail" :key="index">
        <b-row>
            <b-col cols="2" sm="1" class="profileImg">
                <b-avatar v-if="comment.member_profile_url!=''" variant="primary" :src="comment.member_profile_url"></b-avatar>
                <b-avatar v-else variant="primary" :src="require('@/assets/no-avartar.jpg')" ></b-avatar>
            </b-col>
            <b-col>
                <div>
                    <span class="name mr-2">{{comment.member_displayname}}</span>
                    <span class="time">{{comment.comment_moment}}</span>
                </div>
                <div v-if="clickEdit == false">
                    <div class="commentDetail" v-html="comment.comment_detail"></div>
                    <div class="mt-3" v-if="isLogin">
                        <a-icon v-if="comment.is_like" class="pink" type="like" theme="filled" @click="likeOrDislike(comment.comment_id,1,index,comment.is_like)" />
                        <a-icon v-else class="pink" type="like" theme="outlined" @click="likeOrDislike(comment.comment_id,1,index,comment.is_like)" />
                        <span class="pink ml-1 mr-4">{{ comment.like }}</span>
                        <a-icon v-if="comment.is_dislike" class="pink" type="dislike" theme="filled" @click="likeOrDislike(comment.comment_id,2,index,comment.is_dislike)" />
                        <a-icon v-else class="pink" type="dislike" theme="outlined" @click="likeOrDislike(comment.comment_id,2,index,comment.is_dislike)" />
                        <span class="pink ml-1 mr-4">{{ comment.dislike }}</span>
                        <b-icon-pen class="pen mr-4" v-if="comment.self" @click="clickEditComment(comment)"></b-icon-pen>
                        <b-icon-trash class="trash" v-if="comment.self" @click="delComment(comment.comment_id)"></b-icon-trash>
                    </div>
                </div>
                <div class="editComment" v-else-if="clickEdit && edit_commentid == comment.comment_id">
                    <b-row>
                        <b-col style="padding:0px">
                            <b-form-textarea :autofocus="true" id="textarea" class="newComment" v-model="editComment" placeholder="แสดงความคิดเห็น..." rows="3" max-rows="6"></b-form-textarea>
                            <div class="tools">
                                <span class="tool" @click="addTool('bold','edit')">
                                    <b-icon-type-bold font-scale="1.2"></b-icon-type-bold>
                                </span>
                                <span class="tool" @click="addTool('img','edit')">
                                    <b-icon-image font-scale="1.2"></b-icon-image>
                                </span>
                                <span class="tool" @click="addTool('url','edit')">
                                    <b-icon-link45deg font-scale="1.2"></b-icon-link45deg>
                                </span>
                                <span class="tool" @click="addTool('stk','edit')">
                                    <b-icon-egg-fried font-scale="1.2"></b-icon-egg-fried>
                                </span>
                                <div v-if="$vssWidth<=540" class="mt-2"></div>
                                <span class="commentBtn" @click="sendEditComment()" v-if="editComment!=''">
                                    บันทึก
                                </span>
                                <span class="commentBtn ml-2" @click="clickEdit = false">
                                    ยกเลิก
                                </span>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
    </div>
    <div class="comment ml-5" v-if="isLogin && replyComment.comment_id!=null">
        <b-row>
            <b-col>
                <b-form-textarea id="textarea" class="newComment" v-model="newComment" placeholder="แสดงความคิดเห็น..." rows="3" max-rows="6"></b-form-textarea>
                <div class="tools">
                    <span class="tool" @click="addTool('bold','new')">
                        <b-icon-type-bold font-scale="1.2"></b-icon-type-bold>
                    </span>
                    <span class="tool" @click="addTool('img','new')">
                        <b-icon-image font-scale="1.2"></b-icon-image>
                    </span>
                    <span class="tool" @click="addTool('url','new')">
                        <b-icon-link45deg font-scale="1.2"></b-icon-link45deg>
                    </span>
                    <span class="tool" @click="addTool('stk','new')">
                        <b-icon-egg-fried font-scale="1.2"></b-icon-egg-fried>
                    </span>
                    <div v-if="$vssWidth<=540" class="mt-2"></div>
                    <span class="commentBtn" @click="sendComment('new')" v-if="newComment!=''">
                        ส่งความคิดเห็น
                    </span>
                </div>
            </b-col>
        </b-row>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import Sticker from './Sticker'
import bbCodeParser from 'js-bbcode-parser';
import {
    mapGetters
} from 'vuex'

export default {
    components: {
        Sticker
    },
    props: ['replyComment'],
    data() {
        return {
            userInfo: JSON.parse(localStorage.getItem('UserInfo')),
            isLogin: localStorage.getItem('UserInfo') != null,
            newComment: "",
            clickEdit: false,
            edit_commentid: 0,
            editComment: "",
            sticker: [],
            stickerForNewComment: true,
            showStickerModal: false
        };
    },
    computed: {
        ...mapGetters(['GET_MODE'])
    },
    watch: {
        GET_MODE() {
            this.checkMode()
        }
    },
    mounted() {
        this.getSticker()
        this.checkMode()
    },
    methods: {
        async addTool(tool, type) {
            if (type == 'new') {
                if (tool == 'bold') {
                    this.newComment += '[b]กรอกข้อความ[/b]\r\n'
                } else if (tool == 'img') {
                    this.newComment += '[img]urlของรูป[/img]\r\n'
                } else if (tool == 'url') {
                    this.newComment += '[url=linkที่ต้องการ]ข้อความที่ต้องการแสดง[/url]\r\n'
                } else if (tool == 'stk') {
                    this.stickerForNewComment = true
                    this.showStickerModal = true
                }
            } else if (type == 'edit') {
                if (tool == 'bold') {
                    this.editComment += '[b]กรอกข้อความ[/b]\r\n'
                } else if (tool == 'img') {
                    this.editComment += '[img]urlของรูป[/img]\r\n'
                } else if (tool == 'url') {
                    this.editComment += '[url=linkที่ต้องการ]ข้อความที่ต้องการแสดง[/url]\r\n'
                } else if (tool == 'stk') {
                    this.stickerForNewComment = false
                    this.showStickerModal = true
                }
            }
        },
        stickerSelected(slug) {
            if (this.stickerForNewComment) {
                this.newComment += slug + '\r\n'
            } else {
                this.editComment += slug + '\r\n'
            }
            this.showStickerModal = false
        },
        async getSticker() {
            const {
                data
            } = await axios.get('https://api2.novelrealm.com/json/sticker/sticker.json')
            this.sticker = data
            //console.log(this.sticker)
        },
        async likeOrDislike(comment_id, type, index, statusBeforeClick) {
            //console.log('statusBeforeClick : ', statusBeforeClick)
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            formData.append('comment_id', comment_id);
            formData.append('type', type); // type 1/2 (1=like, 2=dislike)
            try {
                await axios.post('https://api2.novelrealm.com/module/comment&submodule=like', formData)
                    .then((res) => {
                        //console.log("likeOrDislike : ", res);
                        //console.log("this.replyComment : ", this.replyComment);
                        if (type == 1) {
                            this.replyComment.detail[index].is_like = !statusBeforeClick
                            //console.log("1 : ", this.replyComment.detail[index].is_like)
                        } else if (type == 2) {
                            this.replyComment.detail[index].is_dislike = !statusBeforeClick
                            //console.log("2 : ", this.replyComment.detail[index].is_dislike)
                        }
                        this.replyComment.detail[index].like = res.data.like
                        this.replyComment.detail[index].dislike = res.data.dislike
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        async sendComment() {
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            formData.append("comment_id", this.replyComment.comment_id);
            formData.append("msg", bbCodeParser.parse(this.newComment));
            try {
                await axios.post('https://api2.novelrealm.com/module/comment&submodule=add', formData)
                    .then((res) => {
                        //console.log('add comment : ', res)
                        if (res.data.status == 200) {
                            this.$emit("getReplyComment");
                            this.newComment = ""
                            //console.log('sendComment')
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        clickEditComment(comment) {
            setTimeout(() => {
                this.checkMode()
            }, 5);
            this.clickEdit = true
            this.edit_commentid = comment.comment_id
            this.editComment = comment.comment_detail

            this.editComment = this.editComment.replace(/<strong>/g, "[b]")
            this.editComment = this.editComment.replace(/<\/strong>/g, "[/b]")
            this.editComment = this.editComment.replace(/<br \/>/g, "")

            var comment_sentence = this.editComment

            var src = 'src="'
            var count_src_all = comment_sentence.match(/src="/g) == null ? 0 : comment_sentence.match(/src="/g).length
            var index_src = []
            var count_src_found = 0
            var srcOfImg = []
            for (var index1 = 0; index1 < comment_sentence.length; index1++) {
                if (comment_sentence.indexOf(src, index1) != -1) {
                    index_src.push(comment_sentence.indexOf(src, index1))
                    count_src_found += 1
                    index1 = comment_sentence.indexOf(src, index1)

                    srcOfImg.push(comment_sentence.substring(index1 + 5, comment_sentence.indexOf('"', index1 + 5)))
                    if (count_src_found == count_src_all) {
                        break;
                    }
                }
            }

            for (var n = 0; n < srcOfImg.length; n++) {
                if (srcOfImg[n].match('sticker/pack/')) {
                    var url_sticker = srcOfImg[n].substring(srcOfImg[n].indexOf('pack/'))
                    var alt = this.sticker[this.sticker.findIndex(x => x.url === url_sticker)].title
                    var numberFront = srcOfImg[n].substring(srcOfImg[n].indexOf('pack/') + 5, srcOfImg[n].indexOf('pack/') + 7)
                    var numberBack = srcOfImg[n].substring(srcOfImg[n].indexOf('pack/') + 8, srcOfImg[n].indexOf('pack/') + 10)
                    this.editComment = this.editComment.replace('<img src="' + srcOfImg[n] + '" alt="' + alt + '" class="img-fluid comment-sticker">', "[sticker-" + numberFront + "-" + numberBack + "]")
                } else {
                    this.editComment = this.editComment.replace('<img src="' + srcOfImg[n] + '">', "[img]" + srcOfImg[n] + "[/img]")
                }
            }

            var href = 'href="'
            var count_href_all = comment_sentence.match(/href="/g) == null ? 0 : comment_sentence.match(/href="/g).length
            var index_href = []
            var count_href_found = 0
            var hrefOfLink = []
            for (var index = 0; index < comment_sentence.length; index++) {
                if (comment_sentence.indexOf(href, index) != -1) {
                    index_href.push(comment_sentence.indexOf(href, index))
                    index = comment_sentence.indexOf(href, index)

                    hrefOfLink.push({
                        href: comment_sentence.substring(index + 6, comment_sentence.indexOf('"', index + 6)),
                        text: comment_sentence.substring(comment_sentence.indexOf('>') + 1, comment_sentence.indexOf('<', index + 6))
                    })
                    //console.log("hrefOfLink : ", hrefOfLink)
                    this.editComment = this.editComment.replace(/<a href/g, "[url")
                    this.editComment = this.editComment.replace(/<\/a>/g, "[/url]")
                    this.editComment = this.editComment.replace('"' + hrefOfLink[count_href_found].href + '">', hrefOfLink[count_href_found].href + ']')
                    count_href_found += 1
                    if (count_href_found == count_href_all) {
                        break;
                    }
                }
            }
        },
        async sendEditComment() {
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            formData.append("comment_id", this.edit_commentid);
            formData.append("msg", bbCodeParser.parse(this.editComment));
            try {
                await axios.post('https://api2.novelrealm.com/module/comment&submodule=edit', formData)
                    .then((res) => {
                        //console.log('edit comment : ', res)
                        if (res.data.status == 200) {
                            this.clickEdit = false
                            this.$emit("getReplyComment");
                            this.edit_commentid = 0
                            this.editComment = ""
                            //console.log('sendEditComment')
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        async delComment(comment_id) {
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            formData.append("comment_id", comment_id);
            try {
                await axios.post('https://api2.novelrealm.com/module/comment&submodule=delete', formData)
                    .then((res) => {
                        //console.log('del comment : ', res)
                        if (res.data.status == 200) {
                            this.$emit("getReplyComment");
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        checkMode() {
            if (this.GET_MODE == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementsByClassName('comment').forEach(element => {
                element.classList.remove('darkmode')
            });
            document.getElementsByClassName('newComment').forEach(element => {
                element.classList.remove('darkmode')
            });
        },
        darkmode() {
            //console.log(document.getElementsByClassName('newComment'))
            document.getElementsByClassName('comment').forEach(element => {
                element.classList.add('darkmode')
            });
            document.getElementsByClassName('newComment').forEach(element => {
                element.classList.add('darkmode')
            });
        }
    }
};
</script>

<style scoped>
.comment {
    padding: 2% 2% 2% 2%;
    border: 2px solid #ffffff;
    border-radius: 7px;
    background-color: #ffffff;
    border: none;
    box-shadow: none;
}

.darkmode {
    background-color: #35363A;
    border: 2px solid #35363A;
    color: #ffffff;
}

.darkmode:focus {
    background-color: #35363A;
    color: #ffffff;
}

.newComment {
    border: none;
    padding: 0px;
}

.form-control:focus {
    box-shadow: none;
}

.tools {
    padding: 0.3rem 0.75rem 0rem 0.75rem;
}

.tool {
    margin-right: 1%;
    padding: 7px
}

.tool:hover {
    color: #040246;
    cursor: pointer;
    background-color: rgba(212, 230, 237, 0.4);
    border-radius: 6px;
}

.pink {
    color: #EE806B;
    font-size: 14px;
}

.badge-primary {
    color: #fff;
    border: 1px solid #040246;
}

.name {
    font-size: 14px;
}

.time {
    font-size: 12px;
    color: #ADADAD;
}

.reply {
    font-size: 13px;
}

.reply:hover {
    cursor: pointer;
}

.commentBtn {
    background-color: #F5D087;
    border-radius: 6px;
    padding: 6px 10px;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
}

.commentBtn:hover {
    background-color: #FAC762;
    cursor: pointer;
}

.pen,
.trash {
    font-size: 17px;
}

.pen:hover,
.trash:hover {
    cursor: pointer;
}

.commentDetail:deep(img) {
    width: 150px !important;
}

@media screen and (max-width: 500px) {
    .profileImg {
        padding: 2px;
    }
}
</style>
